import { Suspense } from "react";
import ReactDOM from 'react-dom/client';
import './assets/styles/index.css';
import Index from './pages/index';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div>
    <Suspense fallback={<h1>Loading...</h1>}>
      <Index />
    </Suspense>
  </div>
);
