import { Typography } from '@mui/material';
import setting from '../../../setting.json';

const CopyRight = () => {
  return (
    <Typography
      id='dela-gothic-one-regular'
      color={setting.Footer.CopyRight.Color}
      sx={{
        position: 'relative',
        fontSize: setting.Footer.CopyRight.FontSize,
        marginLeft: '70px',
        textAlign: 'center',
        mx: '10%'
      }}
    >
      {setting.Footer.CopyRight.Title}
      <Typography>
        {setting.Footer.CopyRight.CopyRight}
      </Typography>
    </Typography>
  );
}

export default CopyRight;