import { Splide, SplideSlide } from "@splidejs/react-splide";
import '@splidejs/splide/css';
import '../../assets/styles/slider.css';
import setting from '../../setting.json';
function Slider() {
  return (
    <Splide
      aria-label={setting.Slide.label}
      options={{
        autoplay: setting.Slide.autoplay,
        interval: setting.Slide.interval,
        speed: setting.Slide.speed,
        type: 'loop',
        // eslint-disable-next-line no-dupe-keys
        type: setting.Slide.type,
        rewind: true
      }}
    >
      {
        setting.Slide.Image.map(item => (
          <SplideSlide>
            <img className="slide-img" src={item.uri} alt={item.alt} />
          </SplideSlide>
        ))
      }
    </Splide>
  );
};
export default Slider;