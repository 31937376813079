import Youtube from '../../../assets/imgs/Footer/Youtube.webp';
import Twitter from '../../../assets/imgs/Footer/Twitter.webp';
import { Box, CardMedia, useMediaQuery } from '@mui/material';
import setting from '../../../setting.json';

const Media = () => {
  const breaks = useMediaQuery("(min-width:900px)");
  return (
    <Box sx={{
      position: 'relative', display: 'flex',
      marginLeft: breaks ? "47%" : "40%"
    }}>
      <a href={setting.Footer.Media.Twitter} target='_blank' rel='noopener noreferrer'>
        <CardMedia
          component='img'
          src={Twitter}
          draggable={false}
          alt='Twitter'
          sx={{
            height: '50px',
            width: '50px',
            marginRight: '10px',
          }}
        />
      </a>
      <a href={setting.Footer.Media.Youtube} target='_blank' rel='noopener noreferrer'>
        <CardMedia
          component='img'
          src={Youtube}
          draggable={false}
          alt='Youtube'
          sx={{
            height: '50px',
            width: '50px'
          }}
        />
      </a>
    </Box>
  );
}

export default Media;