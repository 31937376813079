import { Box } from '@mui/material';
import setting from '../../../setting.json';
const Back = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: setting.Footer.BackColor.height,
        background: setting.Footer.BackColor.Color,
        position: 'absolute',
      }}
    />
  );
};

export default Back;