import { Container, Typography, useMediaQuery } from '@mui/material';
import setting from '../../setting.json';

function Topics() {
  const breaks = useMediaQuery("(min-width:600px)");
  return (
    <div>
      <Container maxWidth="xs">
        <Typography id='dotgothic16-regular' variant={setting.Topics.Title.FontSize} sx={{ textAlign: 'center', marginTop: 20 }}>
          {setting.Topics.Title.Text}
        </Typography>
        <div style={{ width: "100%", height: "4px", backgroundColor: setting.Topics.Title.LineColor }} />
      </Container>
      <Typography id='dotgothic16-regular' fontSize={breaks ? setting.Topics.Description.PC_FontSize : setting.Topics.Description.Smart_FontSize} sx={{ whiteSpace: "pre-wrap", textAlign: 'center', mx: '10%' }}>
        {setting.Topics.Description.Text}
      </Typography>
    </div>
  );
};
export default Topics;