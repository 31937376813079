import { Box } from '@mui/material';
import BackColor from './footer/backcolor';
import CopyRight from './footer/copyright';
import Media from './footer/media';
function Footer() {
  return (
  <Box sx={{ marginTop: 8 }}>
    <BackColor />
    <Media />
    <CopyRight />
  </Box>
  );
}

export default Footer;