import { Box, Toolbar } from '@mui/material';
import Slider from '../components/index/slider';
import Topics from '../components/index/topics';
import Header from '../components/index/header';
import Footer from '../components/index/footer';
function Index() {
  return (
    <Box sx={{ display: 'flex' }}>
      <Header />
      <Box component="main">
        <Toolbar />
        <Slider />
        <Topics />
        <Footer />
      </Box>
    </Box>
  );
}
export default Index;
